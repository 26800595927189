import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import loader from "./Assets/gif/Ripple-1s-200px.gif";
import Contact from "./Components/Contact";

// Lazy load your components
const Home = React.lazy(() => import("./Components/Home"));
const SuccessStories = React.lazy(() => import("./Components/SuccessStories"));
const Blog = React.lazy(() => import("./Components/Blog"));
const Blogdetails = React.lazy(() => import("./Components/Blogdetails"));
const Fitness = React.lazy(() => import("./Components/Fitness"));
const Education = React.lazy(() => import("./Components/Education"));
const Nutrition = React.lazy(() => import("./Components/Nutrition"));
const Psychology = React.lazy(() => import("./Components/Psychology"));
const Finance = React.lazy(() => import("./Components/Finance"));
const Services = React.lazy(() => import("./Components/Services"));
const Subservices = React.lazy(() => import("./Components/Subservices"));
const SubSubservices = React.lazy(() => import("./Components/SubSubservices"));
const Pricing = React.lazy(() => import("./Components/Pricing"));
const Terms = React.lazy(() => import("./Components/Terms"));
const Privecy = React.lazy(() => import("./Components/Privecy"));
const Refund = React.lazy(() => import("./Components/Refund"));
const Partner = React.lazy(() => import("./Components/Partner"));
const Portfolio = React.lazy(() => import("./Components/Portfolio"));
const Columnists = React.lazy(() => import("./Components/Columnists"));
const Contacts = React.lazy(() => import("./Components/Contact"));
const Usercontact = React.lazy(() => import("./Components/Usercontact"));
// const Searchresult = React.lazy(()=> import('./Components/Searchresult'));
function App() {
  return (
    <Suspense
      fallback={
        <div
          className="d-flex flex-column w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        ></div>
      }
    >
      <Router>
        <Routes>
          <Route path="/about" element={<Home />} />
          <Route path="/" element={<SuccessStories />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blogdetails/:id" element={<Blogdetails />} />
          <Route path="/fitness" element={<Fitness />} />
          <Route path="/education" element={<Education />} />
          <Route path="/nutrition" element={<Nutrition />} />
          <Route path="/psychology" element={<Psychology />} />
          <Route path="/finance" element={<Finance />} />
          <Route path="/services" element={<Services />} />
          <Route path="/sub-services/:id" element={<Subservices />} />
          <Route path="/sub-sub-services/:id" element={<SubSubservices />} />
          <Route path="/pricing/:id" element={<Pricing />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privecy />} />
          <Route path="/refund-policy" element={<Refund />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contacts />} />
          <Route path="/columnist" element={<Columnists />} />
          <Route path="/consult" element={<Usercontact />} />
          {/* <Route path="/search-result" element={<Searchresult />} /> */}
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
