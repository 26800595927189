// searchSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_KEY;

export const searchHandler = createAsyncThunk('search/searchHandler', async (query) => {
  try {
    const response = await axios.get(`${url}/search?query=${query}`);
    console.log("search" , response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
});

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    query: '',
    results: [],
    isLoading: false,
    isError: false,
  },
  reducers: {
    updateQuery: (state, action) => {
      state.query = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchHandler.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(searchHandler.fulfilled, (state, action) => {
        state.isLoading = false;
        state.results = action.payload;
      })
      .addCase(searchHandler.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { updateQuery } = searchSlice.actions;
export const selectSearchQuery = (state) => state.search.query;
export const selectSearchResults = (state) => state.search.results;
export const selectSearchLoading = (state) => state.search.isLoading;
export const selectSearchError = (state) => state.search.isError;

export default searchSlice.reducer;
