import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';


const url=process.env.REACT_APP_API_KEY
// import axios from 'axios';


function Popup(props) {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")));
  const [offer, setoffer] = useState(0)
  const [msg, setmsg] = useState('')
  const [alrt, setalrt] = useState(false);
  const [errors, setErrors] = useState({});

  const [details,setdetails]=useState({
    "full_name": "",
    "email": "",
    "contact_number": "",
    "coupon_code": "",
    "payment_mode": "CASH",
} )



  useEffect(() => {
    setCart(JSON.parse(localStorage.getItem("cart")));
  }, [props.Opnner]);
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!details.full_name.trim()) {
      errors.full_name = "Full name is required";
      isValid = false;
    }

    if (!details.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(details.email)) {
      errors.email = "Email address is invalid";
      isValid = false;
    }

    if (!details.contact_number.trim()) {
      errors.contact_number = "Contact number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(details.contact_number)) {
      errors.contact_number = "Contact number must be 10 digits";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  
  // const removeItem = (index) => {
  //   const updatedCart = { ...cart };
  //   const removedItemPrice = updatedCart.cartitem[index].discounted_price;
  //   updatedCart.cartitem.splice(index, 1);
  //   updatedCart.total -= removedItemPrice; 
  //   localStorage.setItem("cart", JSON.stringify(updatedCart));
  //   setCart(updatedCart); 
  // };

  const removeItem = (index) => {
    const updatedCart = { ...cart };
    const removedItemPrice = updatedCart.cartitem[index].discounted_price;
    const newCartItems = updatedCart.cartitem.filter((_, i) => i !== index); 
    updatedCart.cartitem = newCartItems;
    updatedCart.total -= removedItemPrice; 
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCart({ ...updatedCart }); 
    // document.location.reload()
  
  };


  
  const Carthandler=()=>{
    localStorage.removeItem("cart")
    document.location.reload()
  }

 

const handleChange = (e) => {
  const { id, value } = e.target;
  setdetails((prevFormData) => ({
    ...prevFormData,
    [id]: value,
  }));
};

const Opnner = (msg) => {
  setmsg(msg)
  setalrt(true)
  setTimeout(() => {
    setalrt(false)
  }, 5000);
}

// console.log(details);
  const Planhandler=async()=>{
    if (validateForm()) {
    try {
      details["plan_id"]=1
      details["amount"]=cart.total-offer
      console.log(details)
      const response = await axios.post(url+'/core/checkout/',details);
      if(response.status==200){
        // toast.success(response.data.message);
        Opnner(response.data.message)
        localStorage.removeItem("cart")
        
        setTimeout(function(){
          document.location.reload()
                }, 2000);
      }
     
    } catch (error) {
      console.log(error,'Error')
        if (error.response!=undefined){
              console.log('fail',error)
            
            }
        else{
            console.log(error)
        }
    } 
  }
};

  const checkcoupon=async()=>{
    try {
       
      const headers = {
          'Authorization': `token ${sessionStorage.getItem('token')}`,
          'Content-Type': 'application/json', // Adjust content type as needed
        };
      let jsondata={plan_id:'1',coupon_code:details.coupon_code}
      const response = await axios.post(url+'/core/apply-coupon/',jsondata);
      console.log(response.data)
      setoffer(response.data.discounted_price)
      Opnner(`Hurry! You can get ${response.data.discounted_price} Rs off on this plan! Don't miss out on this fantastic deal!`)
    } catch (error) {
      console.log(error,'Error')
        if (error.response!=undefined){
              console.log('fail',error)
              Opnner("Coupon is not valid or expired")
            }
        else{
            console.log(error)
        }
    } 
  }

  const refreshPopupBgrnd = ()  =>{
    document.location.reload()

  }

  return (
    <>
      
      <Modal className="d-flex justify-content-center"
        aria-labelledby="contained-modal-title-vcenter" show={props.isOpen} >
        <div className='row m-0 justify-content-center'>
          <div className='d-flex headermodel'>
            <label className='font_700 font_20'>CHECKOUT</label>
            <button style={{cursor:"pointer"}} onClick={() => { props.toggle(); refreshPopupBgrnd(); }}><FontAwesomeIcon icon={faTimes}  /></button>
          </div>
          <div className='d-flex flex-column p-3'>
            {cart!=null && cart.cartitem!=undefined &&  cart.cartitem.map((item,index)=><div className='d-flex' key={index}>
              <p className='col-lg-8 p-0 font_13 font_600'>Nutrition And Fitness Guidance_Weight Loss Program_Beach Body Programme - {item.discounted_price===10000?"1 Month":item.discounted_price===45000?'6 Months':'Quarterly'}</p>
              <div className='col-lg-4 p-0 d-flex flex-column align-items-end'>
                <span className='font_22 font_700'>₹ {item.discounted_price}</span>
                <span><s>₹ {item.price}</s></span>
                <span style={{cursor:"pointer"}} onClick={() => removeItem(index)}>Remove Item</span>
              </div>
            </div>)}
            
            <div className='d-flex justify-content-end mt-1'>
              <label className='font_14 font_700' onClick={Carthandler}>EMPTY CART</label>
            </div>
          </div>
          <div className='row m-0 p-2'>
            <div class="Layout_inputData w-100">
              <input type="text"  value={details.full_name} onChange={handleChange} id="full_name" />
              <div class="Layout_underline">
              </div>
              <label>Full Name</label>
              {errors.full_name && <p className="error-msg">{errors.full_name}</p>}

            </div>
            <div class="Layout_inputData w-100 mt-5">
              <input type="email" value={details.email} onChange={handleChange} required="" id="email" />
              <div class="Layout_underline">
              </div>
              <label>Email</label>
              {errors.email && <p className="error-msg">{errors.email}</p>}

            </div>
            <div class="Layout_inputData w-100 mt-5">
              <input type="tel" value={details.contact_number} onChange={handleChange} required="" id="contact_number" />
              <div class="Layout_underline">
              </div>
              <label>Contact number</label>
              {errors.contact_number && <p className="error-msg">{errors.contact_number}</p>}

            </div>
          </div>
          <div className='w-100 p-3 '>
            <div className='d-flex w-100'>
              <div className='d-flex coupon col-lg-6 pr-1 justify-content-center mt-1 p-0'>
                <input className='w-100  font_13 p-2' value={details.coupon_code} onChange={handleChange}  id="coupon_code" placeholder='Coupon code' type='text' />
              </div>
              <div className=' d-flex col-lg-6 pl-1 justify-content-center mt-1 p-0'>
                <button className='w-100 couponbtn font_13 font_600' style={{padding:"12px"}} onClick={checkcoupon}>APPLY COUPON</button>
              </div>
            </div>
            <button className='w-100 paybtn font_14 font_700 mt-2' onClick={Planhandler} style={{padding:"12px"}}>PAY IN CASH</button>
          </div>
          <div className='d-flex w-100 footermodel'>
            <span className='col-lg-6 font_700 font_20'>₹ {cart!==null&&cart.total-offer}</span>
            {/* <button className='col-lg-6 p-3'>PROCEED TO PAYMENT</button> */}
          </div>
        </div>
        {alrt && <label className='position-fixed font_20 paybtn p-3 font_600' style={{ zIndex: '22' }}>
        {msg}
      </label>}


      </Modal >
    </>
  )
}

export default Popup