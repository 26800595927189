import React, {useEffect      } from "react";
import Navbar from "../ReusableComponents/Navbar";
import Footer from "../ReusableComponents/Footer";

function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <section
        className="d-flex flex-column align-items-center jsutify-content-center universalbackground"
        style={{ marginBottom: "-6rem", height: "100vh" }}
      >
        <div
          className="aboutalan font_w mb-5 pt-5 "
          style={{ marginTop: "10rem", height: "100vh" }}
        >
          <h2 className="text-center mb-5">
            <strong>Contact Us</strong>
          </h2>
          <p>
            If you have any questions or need further information, please feel
            free to contact us. Our team is here to assist you with your
            inquiries.
          </p>
          <div>
            <p>Name: Alan Baptist</p>
            <p>Address: 77/C Dilkhusha Street, Kolkata - 700017</p>
            <p>Contact Number: +91 98361 43134</p>
            <p style={{ color: "#ffe972" }}>
              We look forward to hearing from you!
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
