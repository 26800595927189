import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const url = process.env.REACT_APP_API_KEY;

export const Bloghandler = createAsyncThunk("Bloghandler", async () => {
  try {
    const headers = {
      Authorization: `token ${sessionStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    const response = await axios.get(url + "/blog/get-create-blog/");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error, "Error");
    if (error.response != undefined) {
      console.log("fail", error);
      throw error.response;
    } else {
      console.log(error);
      throw error.response;
    }
  }
});

const blogSlice = createSlice({
  name: "blogapis",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(Bloghandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(Bloghandler.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(Bloghandler.rejected, (state, action) => {
      console.log(action.state);
      state.isError = true;
      console.log(action.payload);
    });
  },
});

export default blogSlice.reducer;
