import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
const url=process.env.REACT_APP_API_KEY

export const Servicehandler=createAsyncThunk('Servicehandler',async()=>{
    try {
        const response = await axios.get(url+'/core/main-services/');
        console.log(response.data)
        return response.data
       
      } catch (error) {
        console.log(error,'Error')
          if (error.response!=undefined){
                console.log('fail',error)
                throw error.response
              }
          else{
            console.log(error)
              throw error.response
          }
      } 
})


const serviceSlice = createSlice({
    name:'services',
    initialState:{
        isLoading:false,
        data:null,
        isError:false
    },
    extraReducers:(builder)=>{
        builder.addCase(Servicehandler.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.data= action.payload;
        });
        builder.addCase(Servicehandler.pending,(state,action)=>{
            state.isLoading = true
        });
        builder.addCase(Servicehandler.rejected,(state,action)=>{
            console.log(action.state)
            state.isError=true;
            console.log(action.payload);

        });
    }

})

export default serviceSlice.reducer;