import { configureStore } from "@reduxjs/toolkit";
import blogReducer from "./Slice/Blogsapi";
import serviceReducer from "./Slice/Servicesapi";
import subblogapisReducer from "./Slice/Subblogapi";
import planReducer from "./Slice/Planapi";
import searchReducer from "./Slice/Searchapi";
import subserviceReducer from "./Slice/SubServicesapi";

const store = configureStore({
  reducer: {
    blogapis: blogReducer,
    services: serviceReducer,
    subblogs: subblogapisReducer,
    getplan: planReducer,
    search: searchReducer,
    subservices: subserviceReducer,
  },
});

export default store;
