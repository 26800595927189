import { faBars, faCartShopping, faEnvelope, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import { Link, useNavigate, usehistory  } from 'react-router-dom';
import about from '../Assets/icons/about.png'
import blog from '../Assets/icons/Blog_White.png'
import contact from '../Assets/icons/contact.png'
import services from '../Assets/icons/services.png'
import story from '../Assets/icons/story.png'
import { faFacebook, faInstagram, faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Popup from '../Popups/Popup';
// import { searchHandler, updateQuery, selectSearchLoading, selectSearchError } from '../Reduxstate/Slice/Searchapi';

// import menubar from '../Assets/icons/menu-icon.svg'

function Navbar() {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const dispatch = useDispatch();
  
  const history  = useNavigate();
  const [sidebar, setsidebar] = useState(false)
  const [alrt, setalrt] = useState(false)
  const [isopen, setisopen] = useState(false)
  const item = JSON.parse(localStorage.getItem("cart"))

  const allRoutes = [
    'about',
    'services',
    'blogs',
    'contact',
    'blogdetails',
    'fitness',
    'education',
    'nutrition',
    'psychology',
    'finance',
    'pricing',
    'subsubservices',
    'subservices',
    'terms-and-conditions',
    'privacy-policy',
    'refund-policy',
    'partner',
    'portfolio',
    'columnist',
    'consult',
  ];


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const contactusScroll = () => {
    window.scrollTo({
      top: 5100,
      behavior: "smooth",
    });
  };
  const storyScroll = () => {
    window.scrollTo({
      top: 2000,
      behavior: "smooth",
    });
  };
  const Opnner = () => {
    if (item != null) {

      setisopen(true)
       }
    else {
      setalrt(true)
      setTimeout(() => {
        setalrt(false)
      }, 5000);
    }
  }




  const handleSuggestionClick = (selectedItem) => {
    const route = selectedItem.name; 
    history(`/${route}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const inputValue = event.target.value.trim().toLowerCase();
      const matchedRoute = allRoutes.find(route => route.toLowerCase() === inputValue);
      if (matchedRoute) {
        history(`/${matchedRoute}`);
      } else {
        history("/");
      }
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg d-flex flex-column">
        <div className='nav1 w-100 p-0'>
          <FontAwesomeIcon onClick={() => setsidebar(!sidebar)} className='font_32' color='#ffe972' icon={faBars} style={{cursor:"pointer"}}/>
          <div className='alanlabel' onClick={() => { history ('/'); scrollToTop() }}>
            <span>ALAN BAPTIST</span>
          </div>
          <button className='btn consultbtn font_17 d-none d-lg-block font_600' onClick={() =>{history ('/consult');}} id='btn'>CONSULT</button>
          <span className='position-absolute  font_y d-block d-lg-none' style={{ margin: "-3.2rem 1rem 0rem 0rem", right: 0 }}>{item != null && item.cartitem!=undefined && item.cartitem.length}</span>
          <FontAwesomeIcon className='font_32 d-block d-lg-none' onClick={Opnner} style={{ cursor: "pointer" }} color='#ffe972' icon={faCartShopping} />
        </div>
        <div className=' d-none d-lg-flex justify-content-center w-100'>
          <div className="navcontainer m-0">
            <div onClick={() => {history ('/about');scrollToTop()}} ><img className='mr-2' src={about} alt="about" height="22" width="22" /> ABOUT ALAN</div>
            <div onClick={() => { history ('/'); storyScroll() }}><img className='mr-2' src={story} alt="about" height="22" width="22" /> SUCCESS STORIES</div>
            <div onClick={() => {history ('/blogs');scrollToTop() }} ><img className='mr-2' src={blog} alt="about" height="22" width="22" /> BLOGS</div>
            <div onClick={() => {history ('/services');scrollToTop() }} ><img className='mr-2' src={services} alt="about" height="22" width="22" /> SERVICES</div>
            <div onClick={() => { history ('/contact');  }} ><img className='mr-2' src={contact} alt="about" height="22" width="22" /> CONTACT</div>
          </div>
          <div className='cartcontainer'>
            <span className='position-absolute font_y' style={{ margin: "-1.2rem 0rem 0rem 2rem" }}>{item != null && item.cartitem!=undefined && item.cartitem.length}</span>
            <FontAwesomeIcon className='font_32' style={{zIndex: "55"}} onClick={Opnner} color='#ffe972' icon={faCartShopping} />
          </div>
        </div>
        <Popup isOpen={isopen} toggle={() => setisopen(false)} Opnner={Opnner} />
      </nav>
      <div className='sidebar' style={sidebar ? {} : { width: '0px' }} >
        <div className='pl-2 pt-5 pr-2'>
          <div className='d-flex justify-content-end'>
            <FontAwesomeIcon className="position-absolute font_30" style={{ margin: '0 2rem 0 0', cursor:"pointer" }} onClick={() => setsidebar(false)} color='#ffe972' icon={faTimes} />
          </div>
          <ul className='d-flex flex-column align-items-center p-0 mt-5'>
            <li onClick={() => { history ('/about'); setsidebar(false) }} className="navbar-brand font_14 font_700 mb-2"><img className='mr-2' src={about} alt="about" height="22" width="22" /> ABOUT ALAN</li>
            <li onClick={() => { history ('/'); storyScroll(); setsidebar(false) }} className="navbar-brand font_14 font_700 mb-2"><img className='mr-2' src={story} alt="about" height="22" width="22" /> SUCCESS STORIES</li>
            <li onClick={() => { history ('/blogs'); setsidebar(false) }} className="navbar-brand font_14 font_700 mb-2"><img className='mr-2' src={blog} alt="about" height="22" width="22" /> BLOGS</li>
            <li onClick={() => { history ('/services'); setsidebar(false) }} className="navbar-brand font_14 font_700 mb-2"><img className='mr-2' src={services} alt="about" height="22" width="22" /> SERVICES</li>
            <li onClick={() => {history ('/contact'); setsidebar(false) }} className="navbar-brand font_14 font_700 mb-2"><img className='mr-2' src={contact} alt="about" height="22" width="22" /> CONTACT</li>
            <li className="navbar-brand font_14"><button className='btn consultbtn font_17 font_600' onClick={() => {history ('/consult'); setsidebar(false) }} id='btn'>CONSULT</button></li>
          </ul>
          <hr className='w-100 mt-5' style={{ background: "#fff", opacity: ".25" }} />
          <ul className='d-flex flex-column align-items-center p-0'>
            <li className="navbar-brand font_14" onClick={() => { history ('/partner'); setsidebar(false) }}  >Partners</li>
            <li className="navbar-brand font_14" onClick={() => { history ('/columnist'); setsidebar(false) }}>Columnists</li>
            <li className="navbar-brand font_14" onClick={() => { history ('/portfolio'); setsidebar(false) }}>Portfolio</li>

          </ul>

          <div className='d-flex justify-content-center mb-3'>
           
          <ReactSearchAutocomplete
              className='search'
              items={allRoutes.map(route => ({ id: route, name: route }))}
              onSelect={handleSuggestionClick}
              autoFocus={false}
              placeholder="Search..."
              onKeyPress={handleKeyPress} 
            />

          </div>
          <div className='d-flex justify-content-center mb-4 p-0'>
            <a href='https://www.facebook.com/alanvidyushandrew.baptist'> <FontAwesomeIcon icon={faFacebook} /></a>
            <a href='https://www.instagram.com/alan_baptist/'> <FontAwesomeIcon icon={faInstagram} /></a>
            <a href='https://www.linkedin.com/authwall?trk=bf&trkInfo=AQEDtKdKiC-ReAAAAY0jKhFAiEhziGlTSbX_ko6gSSi15AVORqG-m30_c7_Sc0q_dlfTBlaUgcvMxKmLAMYX_xyayDKVBsKod4TE-AyO9hk79kYzOmJNLIFgY024vMlZXdD-lOw=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Falanvidyushbaptist'> <FontAwesomeIcon icon={faLinkedinIn} /></a>
            <a href='https://mail.google.com/mail/u/0/#inbox'> <FontAwesomeIcon icon={faEnvelope} /></a>
            <a href='https://api.whatsapp.com/send/?phone=919836143134&text=Greetings+good+sir%21%0A%0AI+found+your+website+online+and+had+an+enquiry+to+make.&type=phone_number&app_absent=0'> <FontAwesomeIcon icon={faWhatsapp} /></a>
          </div>
          <label className='font_12 w-100 text-center' style={{cursor:"pointer"}}>© 2021 Alan Baptist <br/> 
          {/* Website Developed by <span className='font_y' onClick={() => document.location.href = 'https://gsatechworld.com'}> GSA Techworld Pvt Ltd</span> */}
          </label>

          <div className='d-flex justify-content-center mb-4 p-0 font_12'>
            <span style={{cursor:"pointer"}} className='mr-1' onClick={() => { history ("/terms-and-conditions"); setsidebar(false) }} >Terms And Conditions</span>|<span style={{cursor:"pointer"}}  onClick={() => { history ("/privacy-policy"); setsidebar(false) }} className='ml-1 mr-1'>Privacy Policy</span>|<span style={{cursor:"pointer"}} onClick={() => { history ("/refund-policy"); setsidebar(false) }} className='ml-1 mr-1'>Refund Policy</span>|<span style={{cursor:"pointer"}} onClick={() => { history ("/contact"); setsidebar(false) }} className='ml-1'>Contact</span></div>
        </div>
      </div>
      {alrt && <label className='position-fixed font_20 paybtn p-3 font_600' style={{ bottom: "8rem", right: '2rem', zIndex: '22' }}>
        No item in Cart
      </label>}
    </>

  )
}

export default Navbar
