import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const url=process.env.REACT_APP_API_KEY

export const Planhandler=createAsyncThunk('Planhandler',async()=>{
    try {
        const response = await axios.get(url+'/core/plan_and_offers/');
        return response.data
       
      } catch (error) {
        console.log(error,'Error')
          if (error.response!=undefined){
                console.log('fail',error)
                throw error.response
              }
          else{
              console.log(error)
              throw error.response
          }
      } 
})


const planSlice = createSlice({
    name:'getplan',
    initialState:{
        isLoading:false,
        data:null,
        isError:false
    },
    extraReducers:(builder)=>{
        builder.addCase(Planhandler.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.data= action.payload;
        });
        builder.addCase(Planhandler.pending,(state,action)=>{
            state.isLoading = true
        });
        builder.addCase(Planhandler.rejected,(state,action)=>{
            console.log(action.state)
            state.isError=true;
            console.log(action.payload);
        });
    }

})

export default planSlice.reducer;