import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const url = process.env.REACT_APP_API_KEY;

export const SubServicehandler = createAsyncThunk(
  "SubServicehandler",
  async (id) => {
    try {
      const response = await axios.get(
        url + `/core/services/?main_service=${id}`
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error, "Error");
      if (error.response != undefined) {
        console.log("fail", error);
        throw error.response;
      } else {
        console.log(error);
        throw error.response;
      }
    }
  }
);

const subserviceSlice = createSlice({
  name: "subservices",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(SubServicehandler.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(SubServicehandler.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(SubServicehandler.rejected, (state, action) => {
      console.log(action.state);
      state.isError = true;
      console.log(action.payload);
    });
  },
});

export default subserviceSlice.reducer;
