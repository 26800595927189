import { faFacebook, faInstagram, faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faArrowUp, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import footerimg from '../Assets/images/footer.png'

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
    <footer >
      <div>
      <img src={footerimg} alt='footer'/>
      </div>
      <div className='footerbody d-flex flex-column align-items-center justify-content-center'>
        <label className='font_24 font_600'>CONTACT US</label>
        
        <hr/>
        <div className='d-inline'>
        <a href='https://www.facebook.com/alanvidyushandrew.baptist' target='_blank'> <FontAwesomeIcon icon={faFacebook}/></a>
            <a href='https://www.instagram.com/alan_baptist/' target='_blank'> <FontAwesomeIcon icon={faInstagram}/></a>
            <a target='_blank' href='https://www.linkedin.com/authwall?trk=bf&trkInfo=AQEDtKdKiC-ReAAAAY0jKhFAiEhziGlTSbX_ko6gSSi15AVORqG-m30_c7_Sc0q_dlfTBlaUgcvMxKmLAMYX_xyayDKVBsKod4TE-AyO9hk79kYzOmJNLIFgY024vMlZXdD-lOw=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Falanvidyushbaptist'> <FontAwesomeIcon icon={faLinkedinIn}/></a>
           <a target='_blank' href='https://mail.google.com/mail/u/0/#inbox'> <FontAwesomeIcon icon={faEnvelope}/></a>
           <a target='_blank' href='https://api.whatsapp.com/send/?phone=919836143134&text=Greetings+good+sir%21%0A%0AI+found+your+website+online+and+had+an+enquiry+to+make.&type=phone_number&app_absent=0'> <FontAwesomeIcon icon={faWhatsapp}/></a>
        </div>
        <p className='text-center p-1'>© 2024 Alan Baptist | Designed & Developed by <span className='font_y' style={{cursor:'pointer'}} onClick={()=>document.location.href='https://gsatechworld.com'}>GSA Techworld Pvt Ltd</span></p>
        <FontAwesomeIcon className='bottomUpbtn' onClick={scrollToTop} icon={faArrowUp}/>
        </div>
       
    </footer>
    </>
  )
}

export default Footer